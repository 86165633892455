// getIsInAutoPlayArea.js
'use strict';

/**
 * Get is in auto play area
 * @param {HTMLElement} ref - DOM element.
 * @return {boolean} Is in auto play area.
 */
const getIsInAutoPlayArea = ({ ref }) => {
  if (!ref) return false;
  const { top, bottom } = ref.getBoundingClientRect();
  const totalHeight = window.innerHeight;
  const listHeight = 280;
  const topGap = (totalHeight - listHeight * 2) / 2;
  const bottomGap = (totalHeight - listHeight * 2) / 2 + listHeight * 2;
  const fineTuningUp = 48;
  const fineTuningedTopGap = topGap - fineTuningUp;
  const fineTuningedBottomGap = bottomGap - fineTuningUp;

  return top > fineTuningedTopGap && bottom < fineTuningedBottomGap;
};

export default getIsInAutoPlayArea;
